<template>
  <div class="user-tab-overview">
    <!-- user project list -->
    <v-card class="mb-7">
      <v-card-title>
        Corporates Worked On
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="projectList"
        hide-default-footer
      >
        <!-- project -->
        <template #[`item.corporate`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="33"
              class="me-3"
            >
              <v-img :src="require(`@/assets/images/icons/project-icons/${item.logo}`)"></v-img>
            </v-avatar>
            <div class="text-no-wrap">
              <p class="font-weight-medium text--primary mb-n1">
                {{ item.name }}
              </p>
              <span class="text-xs text--disabled">{{ item.corporate }}</span>
            </div>
          </div>
        </template>

        <!-- progress -->
        <template #[`item.progress`]="{item}">
          <span>{{ item.progress }}%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveUserProgressVariant(item.progress)"
            :value="item.progress"
          ></v-progress-linear>
        </template>
      </v-data-table>
    </v-card>

    <!-- activity timeline -->
    <v-card class="mb-7">
      <v-card-title>
        User Activity Timeline
      </v-card-title>

      <v-card-text>
        <v-timeline
          dense
          class="timeline-custom-dense timeline-custom-dots"
        >
          <v-timeline-item
            color="error"
            small
          >
            <div class="d-flex justify-space-between align-center flex-wrap">
              <h4 class="font-weight-medium me-1">
                User login
              </h4>
              <small class="text-no-wrap">41 min ago</small>
            </div>
            <p class="mb-0">
              User login at 2:12pm
            </p>
          </v-timeline-item>

          <v-timeline-item
            color="primary"
            small
          >
            <div class="d-flex justify-space-between align-center flex-wrap">
              <h4 class="font-weight-medium me-1">
                Approval from Dennis
              </h4>
              <small class="text-no-wrap">2h ago</small>
            </div>

            <p class="mb-1">
              Second Level request approval by Dennis @10:15am
            </p>

            <div class="d-flex align-center mt-3">
              <v-avatar
                size="40"
                class="me-2"
              >
                <v-img src="@/assets/images/avatars/admin.png"></v-img>
              </v-avatar>
              <div>
                <p class="text--primary font-weight-medium mb-0">
                  Dennis Henry
                </p>
                <span>System Administrator</span>
              </div>
            </div>
          </v-timeline-item>

          <v-timeline-item
            color="info"
            small
          >
            <div class="d-flex justify-space-between align-center flex-wrap">
              <h4 class="font-weight-medium me-1">
                Placed Vaccine Request
              </h4>
              <small class="text-no-wrap">2 days ago</small>
            </div>
            <p class="mb-0">
              Requested Vaccine viles to be disributed accross 8 facilities
            </p>
          </v-timeline-item>

          <v-timeline-item
            color="success"
            small
          >
            <div class="d-flex justify-space-between align-center flex-wrap">
              <h4 class="font-weight-semibold me-1">
                Payment Confirmation
              </h4>
              <small class="text-no-wrap">3 wks ago</small>
            </div>
            <p class="mb-0">
              Approved payment confirmation from Ponea Health
            </p>
            <span class="d-flex align-center mt-2">
              <v-icon
                color="error"
                class="me-1"
              >
                {{ icons.mdiFilePdf }}
              </v-icon>
              <span class="text--primary font-weight-semibold text-sm">payment-receipt.pdf</span>
            </span>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'

export default {
  setup() {
    const tableColumnHeaders = [
      {
        text: 'CORPORATES',
        value: 'corporate',
        sortable: false,
      },
      { text: 'VACCINATED', value: 'vaccinated', sortable: false },
      { text: 'PROGRESS', value: 'progress', sortable: false },
      { text: 'PAID', value: 'paid', sortable: false },
    ]
    const projectList = [
      {
        logo: 'python.png',
        name: 'TechTitans Limited',
        corporate: 'Technology Company',
        vaccinated: '122/240',
        progress: 78,
        paid: '$18.42',
      },
      {
        logo: 'ponea.png',
        name: 'Ponea Health BV',
        corporate: 'Digital Health',
        vaccinated: '9/56',
        progress: 18,
        paid: '$20.42',
      },
      {
        logo: 'ncba.png',
        name: 'NCBA PLC',
        corporate: 'Commercial Bank',
        vaccinated: '290/320',
        progress: 62,
        paid: '$120.87',
      },
      {
        logo: 'xamarin.png',
        name: 'Foodista Delivery',
        corporate: 'Booking',
        vaccinated: '20/301',
        progress: 8,
        paid: '$120.87',
      },
    ]

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    return {
      tableColumnHeaders,
      projectList,
      resolveUserProgressVariant,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedUser: {},
  },
  getters: {},
  mutations: {
    addSelectedUser(state, item) {
      state.selectedUser = item
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, data) {
      return new Promise((resolve, reject) => {
        console.log(data)

        const id = data['id']
        const userData = data.userData

        axios
          .put(`/user/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

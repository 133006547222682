<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-bio-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit User Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating user details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.first_name"
                outlined
                dense
                label="First Name"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.last_name"
                outlined
                dense
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userDataLocal.email"
                outlined
                dense
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                outlined
                dense
                label="Phone"
              >07</v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="userDataLocal.user_status_id"
                :items="statusOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                label="Status"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="userDataLocal.role_id"
                :items="roleOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
                label="Role"
              ></v-select>
            </v-col>

            <!--Switch-->
            <v-col cols="12">
              <v-switch
                v-model="isBillingAddress"
                class="mt-0"
                hide-details
              >
                <template #label>
                  <span class="font-weight-medium text--primary">Confirm updating details for {{ userData.first_name }}?</span>
                </template>
              </v-switch>
            </v-col>

            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                Submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open',false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const isBillingAddress = ref(true)
    const blankUserData = {
      first_name: props.userData.first_name,
      last_name: props.userData.last_name,
      email: props.userData.email,
      role_id: props.userData.roles[0].id,
      user_status_id: props.userData.status.id,
    }

    const userDataLocal = ref({})
    userDataLocal.value = JSON.parse(JSON.stringify(blankUserData))

    const roleOptions = [
      { title: 'Admin', value: '1' },
      { title: 'Editor', value: '3' },
      { title: 'Normal User', value: '5' },
    ]

    const statusOptions = [
      { title: 'Pending', value: '4' },
      { title: 'Active', value: '1' },
      { title: 'Inactive', value: '3' },
    ]

    // on form submit
    const onSubmit = () => {
      emit('update:is-bio-dialog-open', false)
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        userDataLocal.value = JSON.parse(JSON.stringify(blankUserData))
      },
    )

    return {
      isBillingAddress,
      statusOptions,
      roleOptions,
      userDataLocal,
      onSubmit,
    }
  },
}
</script>
